/*
* BASE DEPENDENCIES
*/

@import "abstracts/mixins", "abstracts/functions", "abstracts/vars",
	"abstracts/animations";

/*
* LAYOUTx
*/

.section {
	padding: 50px 0;

	@include min(800px) {
		padding: 80px 0;
	}

	@include min(1280px) {
		padding: 120px 0;
	}

	&__heading {
		color: inherit;
	}
}

.about {
	background: transparent url(/assets/images/pattern-grey.svg) repeat-x left -6px
		bottom 6px;

	&__content {
		display: grid;
		grid-gap: 50px;
		gap: 50px;

		@include min(800px) {
			grid-template-columns: 1fr 360px;
		}

		@include min(1280px) {
			grid-template-columns: 1fr 490px;
		}
	}

	&__content-text {
		color: #7a7a7a;
		font-size: rem(15px);
		line-height: (28 / 17);

		@include min(1280px) {
			font-size: rem(17px);
		}
	}

	&__heading {
		color: #182a3b;
	}
}

.products {
	background: #001931 url(/assets/images/products-bg.jpg) no-repeat center
		center / cover;
	color: white;

	&__heading {
		text-align: center;
	}
}

.support {
	&__heading {
		text-align: center;
		color: $color-brand;
	}
}

.categories {
	text-align: center;
	display: grid;
	grid-gap: 40px;
	gap: 40px;
	color: white;
	margin: 0;

	@include min(700px) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 50px;
		gap: 50px;
	}

	&__icon {
		display: block;
		margin-bottom: 30px;
	}

	&__heading {
		font-family: $font-headings;
		font-size: rem(30px);
		line-height: (32 / 30);
		color: #0d92ff;
		font-weight: bold;
	}

	&__description {
		margin-left: auto;
		margin-right: auto;
		max-width: 28rem;
	}

	@include link {
		color: white;
		text-decoration: underline;
	}

	@include link-over {
		color: #0d92ff;
	}
}

.support-categories {
	display: grid;
	text-align: center;
	grid-gap: 70px;
	gap: 70px;
	margin: 0 auto;
	padding-top: 40px;
	max-width: 600px;

	@include min(990px) {
		max-width: none;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 40px;
		gap: 40px;
	}

	&__item {
		position: relative;
		padding: 48px 32px 32px;
		color: #7a7a7a;
	}

	&__icon {
		border-radius: 50%;
		width: 72px;
		height: 72px;
		background-color: #ebebeb;
		position: absolute;
		z-index: 10;
		top: 0;
		left: 50%;
		transform: translate(-50%, -50%);
		display: inline-flex;
		justify-content: center;
		align-items: center;
		color: #112c3e;
		transition: background-color 0.3s ease-in-out;

		svg {
			fill: currentColor;
		}
	}

	&__description {
		font-size: rem(14px);
		line-height: (23 / 14);
	}

	&__link {
		display: block;
		font-size: rem(30px);
		line-height: (32 / 30);
		font-family: $font-headings;
		font-weight: bold;

		&::before {
			content: "";
			opacity: 0;
			transform: translate(-50%, 200%);
			transition: transform 0.2s ease-out, opacity 0.2s ease-out;
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 49px;
			height: 18px;
			z-index: 10;
			background: transparent
				encode-svg(
					'<svg width="18" height="7" viewBox="0 0 18 7" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M17.636,0.416l-0.711,0.416c-1.474,0.867 -2.705,1.301 -3.676,1.301c-0.503,-0 -1.648,-0.295 -3.451,-0.902c-0.156,-0.034 -0.33,-0.104 -0.503,-0.156c-1.509,-0.52 -2.688,-0.85 -3.503,-0.988c-0.312,-0.052 -0.59,-0.087 -0.798,-0.087c-1.612,0 -3.277,0.728 -4.994,2.168l0.416,3.676l0.711,-0.416c0.468,-0.312 1.058,-0.59 1.769,-0.885c0.017,0 0.017,-0.017 0.035,-0.017c0.745,-0.295 1.37,-0.434 1.907,-0.434c0.399,0 1.301,0.209 2.688,0.642c0.399,0.121 0.85,0.26 1.335,0.416c2.151,0.711 3.607,1.058 4.37,1.058c0.763,0 1.63,-0.225 2.584,-0.694c0.954,-0.485 1.682,-0.971 2.185,-1.456l-0.364,-3.642Z" style="fill:#00569d;fill-rule:nonzero;"/></svg>'
				)
				no-repeat center center / 100% auto;
		}

		&::after {
			@include fill;
			content: "";
			border: 1px #afafaf solid;
			transition: border-color 0.2s ease-in-out;
		}

		&:hover,
		&:focus {
			&::before {
				opacity: 1;
				transform: translate(-50%, 50%);
			}

			&::after {
				border-color: #112c3e;
			}

			.support-categories__icon {
				background-color: $color-brand;
				color: white;
			}
		}
	}

	@include link {
		color: #112c3e;
		text-decoration: none;
	}
}

.strengths {
	background-color: $color-brand;
	color: white;
	padding: 30px;

	@include min(800px) {
		margin-top: -2.75rem;
	}

	@include min(1280px) {
		padding: 50px;
	}

	&__heading {
		font-size: rem(44px);
		line-height: (40 / 32);
		font-weight: bold;
	}

	&__heading-small {
		text-transform: uppercase;
		font-size: rem(17px);
		font-weight: normal;
		line-height: (32 / 17);
		display: block;
		letter-spacing: 0.15em;
	}

	&__list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		overflow: hidden;
	}

	&__item {
		text-transform: uppercase;
		font-family: $font-headings;
		font-size: rem(15px);
		line-height: (26 / 15);
		font-weight: normal;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 0 20px;
		transform: translateX(100%);
		opacity: 0;
		transition: transform 0.5s ease-out, opacity 0.5s ease-out;

		&:nth-child(1) {
			transition-delay: 0.3s;
		}
		&:nth-child(2) {
			transition-delay: 0.6s;
		}
		&:nth-child(3) {
			transition-delay: 0.9s;
		}
		&:nth-child(4) {
			transition-delay: 1.2s;
		}
	}

	&__list.in-view .strengths__item {
		transform: translateX(0);
		opacity: 1;
	}

	&__text {
		position: relative;
		padding-left: 35px;
		margin: 0;

		&::before {
			content: encode-svg(
				'<svg width="14" height="9" viewBox="0 0 14 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M10.129,3.415l-10.129,0l0,2.129l10.169,0l-1.84,1.95l1.42,1.506l4.251,-4.52l-4.251,-4.48l-1.42,1.506l1.8,1.909Z" style="fill:#fff;"/></svg>'
			);
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	&__icon {
		img {
			width: 80px;
			height: auto;
		}
	}
}
