/*
* BASE DEPENDENCIES
*/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*
@keyframes fade-into-foreground {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(1.2);
		opacity: 0;
	}
}

@keyframes fade-into-background {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(.75);
		opacity: 0;
	}
}

@keyframes pop-into-foreground {
	from {
		transform: scale(.5);
		opacity: 0;
	} to {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pop-into-foreground-with-bounce {
	0% {
		transform: scale(.5);
		opacity: 0;
	} 72% {
		transform: scale(1.07);
		opacity: .72;
	} 100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
	} 100% {
		box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0);
	}
}

@keyframes slide-from-top {
	from {
		transform: translateY(-100%);
	} to {
		transform: translateY(0);
	}
}
*/
/*
* LAYOUTx
*/
.section {
  padding: 50px 0;
}

@media screen and (min-width: 800px) {
  .section {
    padding: 80px 0;
  }
}

@media screen and (min-width: 1280px) {
  .section {
    padding: 120px 0;
  }
}

.section__heading {
  color: inherit;
}

.about {
  background: transparent url(/assets/images/pattern-grey.svg) repeat-x left -6px bottom 6px;
}

.about__content {
  display: grid;
  grid-gap: 50px;
  gap: 50px;
}

@media screen and (min-width: 800px) {
  .about__content {
    grid-template-columns: 1fr 360px;
  }
}

@media screen and (min-width: 1280px) {
  .about__content {
    grid-template-columns: 1fr 490px;
  }
}

.about__content-text {
  color: #7a7a7a;
  font-size: 0.9375rem;
  line-height: 1.64706;
}

@media screen and (min-width: 1280px) {
  .about__content-text {
    font-size: 1.0625rem;
  }
}

.about__heading {
  color: #182a3b;
}

.products {
  background: #001931 url(/assets/images/products-bg.jpg) no-repeat center center/cover;
  color: white;
}

.products__heading {
  text-align: center;
}

.support__heading {
  text-align: center;
  color: #00569d;
}

.categories {
  text-align: center;
  display: grid;
  grid-gap: 40px;
  gap: 40px;
  color: white;
  margin: 0;
}

@media screen and (min-width: 700px) {
  .categories {
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    gap: 50px;
  }
}

.categories__icon {
  display: block;
  margin-bottom: 30px;
}

.categories__heading {
  font-family: "Titillium Web", sans-serif;
  font-size: 1.875rem;
  line-height: 1.06667;
  color: #0d92ff;
  font-weight: bold;
}

.categories__description {
  margin-left: auto;
  margin-right: auto;
  max-width: 28rem;
}

.categories a,
.categories a:link,
.categories a:visited {
  color: white;
  text-decoration: underline;
}

.categories a:hover,
.categories a:focus,
.categories a:active {
  color: #0d92ff;
}

.support-categories {
  display: grid;
  text-align: center;
  grid-gap: 70px;
  gap: 70px;
  margin: 0 auto;
  padding-top: 40px;
  max-width: 600px;
}

@media screen and (min-width: 990px) {
  .support-categories {
    max-width: none;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
    gap: 40px;
  }
}

.support-categories__item {
  position: relative;
  padding: 48px 32px 32px;
  color: #7a7a7a;
}

.support-categories__icon {
  border-radius: 50%;
  width: 72px;
  height: 72px;
  background-color: #ebebeb;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #112c3e;
  transition: background-color 0.3s ease-in-out;
}

.support-categories__icon svg {
  fill: currentColor;
}

.support-categories__description {
  font-size: 0.875rem;
  line-height: 1.64286;
}

.support-categories__link {
  display: block;
  font-size: 1.875rem;
  line-height: 1.06667;
  font-family: "Titillium Web", sans-serif;
  font-weight: bold;
}

.support-categories__link::before {
  content: "";
  opacity: 0;
  transform: translate(-50%, 200%);
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 49px;
  height: 18px;
  z-index: 10;
  background: transparent url("data:image/svg+xml;utf8,%3Csvg width='18' height='7' viewBox='0 0 18 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cpath d='M17.636,0.416l-0.711,0.416c-1.474,0.867 -2.705,1.301 -3.676,1.301c-0.503,-0 -1.648,-0.295 -3.451,-0.902c-0.156,-0.034 -0.33,-0.104 -0.503,-0.156c-1.509,-0.52 -2.688,-0.85 -3.503,-0.988c-0.312,-0.052 -0.59,-0.087 -0.798,-0.087c-1.612,0 -3.277,0.728 -4.994,2.168l0.416,3.676l0.711,-0.416c0.468,-0.312 1.058,-0.59 1.769,-0.885c0.017,0 0.017,-0.017 0.035,-0.017c0.745,-0.295 1.37,-0.434 1.907,-0.434c0.399,0 1.301,0.209 2.688,0.642c0.399,0.121 0.85,0.26 1.335,0.416c2.151,0.711 3.607,1.058 4.37,1.058c0.763,0 1.63,-0.225 2.584,-0.694c0.954,-0.485 1.682,-0.971 2.185,-1.456l-0.364,-3.642Z' style='fill:%2300569d;fill-rule:nonzero;'/%3E%3C/svg%3E") no-repeat center center/100% auto;
}

.support-categories__link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  border: 1px #afafaf solid;
  transition: border-color 0.2s ease-in-out;
}

.support-categories__link:hover::before, .support-categories__link:focus::before {
  opacity: 1;
  transform: translate(-50%, 50%);
}

.support-categories__link:hover::after, .support-categories__link:focus::after {
  border-color: #112c3e;
}

.support-categories__link:hover .support-categories__icon, .support-categories__link:focus .support-categories__icon {
  background-color: #00569d;
  color: white;
}

.support-categories a,
.support-categories a:link,
.support-categories a:visited {
  color: #112c3e;
  text-decoration: none;
}

.strengths {
  background-color: #00569d;
  color: white;
  padding: 30px;
}

@media screen and (min-width: 800px) {
  .strengths {
    margin-top: -2.75rem;
  }
}

@media screen and (min-width: 1280px) {
  .strengths {
    padding: 50px;
  }
}

.strengths__heading {
  font-size: 2.75rem;
  line-height: 1.25;
  font-weight: bold;
}

.strengths__heading-small {
  text-transform: uppercase;
  font-size: 1.0625rem;
  font-weight: normal;
  line-height: 1.88235;
  display: block;
  letter-spacing: 0.15em;
}

.strengths__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.strengths__item {
  text-transform: uppercase;
  font-family: "Titillium Web", sans-serif;
  font-size: 0.9375rem;
  line-height: 1.73333;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px;
  transform: translateX(100%);
  opacity: 0;
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}

.strengths__item:nth-child(1) {
  transition-delay: 0.3s;
}

.strengths__item:nth-child(2) {
  transition-delay: 0.6s;
}

.strengths__item:nth-child(3) {
  transition-delay: 0.9s;
}

.strengths__item:nth-child(4) {
  transition-delay: 1.2s;
}

.strengths__list.in-view .strengths__item {
  transform: translateX(0);
  opacity: 1;
}

.strengths__text {
  position: relative;
  padding-left: 35px;
  margin: 0;
}

.strengths__text::before {
  content: url("data:image/svg+xml;utf8,%3Csvg width='14' height='9' viewBox='0 0 14 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cpath d='M10.129,3.415l-10.129,0l0,2.129l10.169,0l-1.84,1.95l1.42,1.506l4.251,-4.52l-4.251,-4.48l-1.42,1.506l1.8,1.909Z' style='fill:%23fff;'/%3E%3C/svg%3E");
  position: absolute;
  left: 0;
  top: 0;
}

.strengths__icon img {
  width: 80px;
  height: auto;
}
